import client from './requestclient';

const paymentSevice = {
    // propertyTransactions: async (id) => {
    //     return await client({URL_PATH: `fetchpropertiestransactions/${id}`, method: "GET"})
    // },
    // walletDetails: async (id) => {
    //     return await client({URL_PATH: `fetchwalletdetails/${id}`, method: "GET"})
    // },
    // walletDebitDetails: async (id) => {
    //     return await client({URL_PATH: `fetchwalletdebitdetails/${id}`, method: "GET"})
    // },
    payment: async (body) => {
        return await client({URL_PATH: 'smartsubscription', method: "POST", body: body})
    }
}

export default paymentSevice;