<template>
  <div class="card" :style="{ backgroundColor: bg }">
    <div class="card-details">
      <slot name="card-details"></slot>
    </div>
    <div class="more-details">
      <slot name="card-footer"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    bg: {
      type: String,
      default: "#ffffff"
    }
  }
};
</script>

<style scoped>
.card {
  /* margin-top: 40px; */
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}
.card a {
  text-decoration: none;
}

.more-details {
  /* background: var(--primary-color); */
  /* color: #fff; */
  height: auto;
  width: 100%;
  border-radius: 0 0 10px 10px;
  overflow: hidden;
}
</style>
