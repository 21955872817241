<template>
  <SidebarLayout>
    <WelcomeBanner v-if="showPromoStuff" />
    <div class="apply-fm-holder" v-if="!appliedForFMIO">
      <div class="row">
        <div class="col-md-8">
          <div class="left-fm" v-if="applySection === 1">
            <h3>Earn More with Shelta!</h3>
            <p>
              Join our Smart FM and Smart IO team to take full advantage of our
              amazing offers.
            </p>
            <div class="apply-fm-btn">
              <button
                class="btn"
                data-toggle="modal"
                data-target="#confirmFMIO"
              >
                Apply here
              </button>
            </div>
          </div>
          <div class="left-fm" v-if="applySection === 2">
            <small class="d-block d-md-none" @click="checkUserDetails()"
              >X</small
            >
            <h3>Awesome!</h3>
            <p>
              Your application has been successfully submitted to Shelta
              Academy. Expect upcoming notifications detailing your next steps
              and exciting opportunities that await you.
            </p>
          </div>
        </div>
        <div class="col-md-4">
          <div class="right-fm">
            <small
              class="d-none d-md-block"
              v-if="applySection === 2"
              @click="checkUserDetails()"
              >X</small
            >
            <img src="../../assets/images/coins.svg" alt="money" />
          </div>
        </div>
      </div>
    </div>
    <!-- Modal -->
    <div
      class="modal fade"
      id="confirmFMIO"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content" style="margin-bottom: 40px;">
          <div class="confirm-holder">
            <p class="confirmation-text">
              Do you want to proceed with this application?
            </p>
            <div class="modal-btn-confirm">
              <button
                class="btn cancel"
                data-dismiss="modal"
                aria-label="Close"
              >
                Cancel
              </button>
              <button
                class="btn continue"
                @click="apply()"
                :disabled="processing"
              >
                {{ processing ? "Applying..." : "Continue" }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <CardsOverflow v-if="showLink || showPromoStuff">
      <!-- <p>{{ subscription }}</p> -->
      <div class="section-one d-none" v-if="subscription == 'YES'">
        <BaseCard v-if="showLink">
          <template v-slot:card-details>
            <div class="recruitment">
              <button class="close" @click="setShowLink()">&times;</button>

              <p>
                Recruitment link <span class="link">{{ userLink }}</span>
              </p>
              <input type="text" v-model="userLink" id="usercodeInput" />
              <div class="link-action">
                <button @click="copy()">Copy link</button>
            
              </div>
            </div>
          </template>
        </BaseCard>
      </div>
    </CardsOverflow>

    <router-view></router-view>
  </SidebarLayout>
</template>

<script>
import SidebarLayout from "./SidebarLayout";
import BaseCard from "../BaseCard.vue";
import CardsOverflow from "./CardsOverflow.vue";
import WelcomeBanner from "../WelcomeBanner.vue";
import userCredentials from "../../services/userCredentials";
import fm from "../../services/fmio.js";
import $ from "jquery";

export default {
  name: "DashboardLayout",
  components: {
    SidebarLayout,
    BaseCard,
    CardsOverflow,
    WelcomeBanner,
  },
  data() {
    return {
      userID: "",
      processing: false,
      userLink: "",
      userCode: "",
      showLink: true,
      weekData: {},
      monthData: {},
      overallData: {},
      subscription: "",
      appliedForFMIO: false,
      applySection: 1,
      user: {},
      expectedPoints: ""
    };
  },
  methods: {
    getDashboardSummary() {
      userCredentials
        .dashboardSummary(this.userID)
        .then(data => {
          // console.log("data", data);
          if (data.success) {
            this.expectedPoints = data.expectedpoint;
          } else {
            this.$toast.error(data.error);
          }
        })
        .catch(() => {
          this.$toast.error("please check your network and refresh the page");
        });
    },
    setShowLink() {
      this.showLink = false;
    },
    copy() {
      let codeInput = document.getElementById("usercodeInput");

      codeInput.select();
      document.execCommand("copy");

      this.$toast.success("copied");
    },
    activateLeaderboard(leaderboard) {
      this.$store.dispatch("setLeaderboardCurrentTab", leaderboard);
    },
    apply() {
      this.processing = true;
      // let body = {};
      // body = JSON.stringify(body);
      if (this.user.smartsubscribed == "NO") {
        this.processing = false;
        return this.$toast.error("Please subscribe");
      }
      if (this.user.verified == "0") {
        this.processing = false;
        return this.$toast.error("You are not verified");
      }
      // if (this.expectedPoints != 30) {
      //   this.processing = false;
      //   return this.$toast.error("Your expected points is less than 30");
      // }
      fm.applyForSheltaFmIo()
        .then((res) => {
          // console.log("fmio res", res);
          this.processing = false;
          if (res.success) {
            this.applySection = 2;
            this.$toast.success("SUCCESS!");
            $("#confirmFMIO")
            .removeClass("fade")
            .modal("hide");
            this.$root.$refs.user.getUserFullDetails();
            this.$root.$refs.userx.checkUserDetails();
          }
          if (res.error) {
            this.$toast.error(res.error);
            $("#confirmFMIO")
            .removeClass("fade")
            .modal("hide");
          }
        })
        .catch((err) => {
          console.log(err);
          this.processing = false;
          this.$toast.error("Error");
          $("#confirmFMIO")
            .removeClass("fade")
            .modal("hide");
        });
    },
    checkUserDetails() {
      this.user = JSON.parse(localStorage.getItem("shelterUser")).user;
      if (this.user.smartfm == 1) {
        this.appliedForFMIO = true;
      }
    },
  },
  created() {
    const user = this.$store.getters.getUser;
    this.userID = user.id;
    const userCode = this.$store.getters.getUser.usercode;
    this.userLink = window.location.origin + "/" + userCode;
    this.userCode = userCode;
    this.subscription = this.$store.getters.getSubscription;
    this.getDashboardSummary();
  },
  computed: {
    showPromoStuff() {
      const acceptedRoutes = ["/user/dashboard"];

      return acceptedRoutes.includes(this.$route.fullPath);
    },
  },
  mounted() {
    // this.subscription;
    this.checkUserDetails();
  },
};
</script>

<style scoped lang="scss">
#usercodeInput {
  border: 0;
  position: absolute;
  left: -500px;
  top: -500px;
}

.confirm-holder {
  padding: 24px;
  margin-top: 20px;
  .confirmation-text {
    font-family: Lato;
    font-size: 20px;
    text-align: center;
  }
}
.modal-btn-confirm {
  margin-top: 40px; 
  text-align: center;
  .cancel {
    border: 1px solid #eb0000;
    color: #eb0000;
    margin-right: 20px;
    outline: 0;
    box-shadow: none;
  }
  .continue {
    background: #0033ea;
    color: #ffffff;
    outline: 0;
    box-shadow: none;
  }
}

.apply-fm-holder {
  margin-top: 24px;
  padding: 24px;
  border-radius: 10px;
  background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.05) 0%,
      rgba(0, 0, 0, 0.05) 100%
    ),
    #fff7e6;
}
.left-fm {
  position: relative;
  small {
    position: absolute;
    right: 0;
    font-size: 20px;
    cursor: pointer;
  }
  h3 {
    color: #2b3352;
    // font-family: Lato;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  p {
    color: #555c74;
    // font-family: Lato;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
  }
}

.apply-fm-btn {
  margin-top: 16px;
  button {
    border-radius: 4px;
    background: #0033ea;
    color: #ffffff;
    text-align: center;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 125%;
    text-transform: capitalize;
    height: 46px;
    outline: 0;
    box-shadow: none;
  }
}

.right-fm {
  position: relative;
  small {
    right: 0;
    position: absolute;
    font-size: 20px;
    cursor: pointer;
  }
  img {
    width: 100%;
  }
}

@media screen and (max-width: 599px) {
  // .apply-fm-holder {
  //   padding: 24px 15px;
  // }
  .left-fm {
    h3 {
      font-size: 24px;
    }
    p {
      font-size: 15px;
    }
  }
  .apply-fm-btn {
    button {
      height: 32px;
      font-size: 14px;
    }
  }
  .right-fm {
    img {
      max-height: 86px;
      margin-top: 20px;
      object-fit: cover;
    }
  }
}

.promo-cards {
  display: flex;
  gap: 1rem;
  flex-direction: column;
}

.promo-cards > * {
  flex: 1;
}

.section-one {
  width: 100%;
  max-width: 350px;
  margin-top: 1rem;
}

.section-one > * {
  width: 100%;
}
.user-status {
  width: fit-content;
  margin-right: 20px;
  margin-top: 30px;
}

.recruitment {
  width: 100%;
  position: relative;
  padding: 1rem;
  z-index: 10;
}
.recruitment .close {
  width: 24px;
  height: 24px;
  background: var(--primary-color);
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  line-height: 24px;
  font-family: "Gotham-Bold";
  font-weight: bold;
  font-size: 16px;
  /* position: absolute; */
  margin-left: auto;
  right: 10px;
  top: 10px;
  border: 0;
}
.recruitment > p {
  font-size: 13px;
  font-family: "Gotham-Book";
  margin-bottom: 17px;
  line-height: 1.6;
}
.recruitment .link {
  color: #6886f0;
}
.link-action {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 7px;
}
.link-action button {
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  border-radius: 5px;
  background: #fff;
  height: 20px;
  width: 74px;
  font-size: 12px;
  font-family: "Gotham-Book";
  line-height: 18px;
}
.link-action button:focus {
  border: 1px solid var(--primary-color);
  outline: 0;
}
@media (max-width: 424.98px) {
  .recruitment {
    width: 100%;
    min-width: unset;
    max-width: unset;
    padding: 20px 10px;
  }
  .recruitment > p {
    text-align: left;
  }
  .recruitment .link {
    margin-top: 10px;
    display: inline !important;
  }
}

@media (min-width: 578px) {
  .section-one {
    flex-direction: row;
    justify-content: space-between;
    align-items: initial;
  }

  .promo-cards {
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }

  .section-one .card {
    /* this accounts for the  3 items in the cardsOverflow component */
    /* flex: 3; */
  }
}
</style>
