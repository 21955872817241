<template>
  <div class="welcome-banner">
    <div class="welcome-holder">
      <h1>
        Hi, <span>{{ $store.getters.getUser.firstname }}</span
        >!
      </h1>
      <span v-if="smartFM" class="fmio-text"><img src="../assets/images/fm-io.svg" alt="icon">Smart FM/IO</span>
      <!-- //TODO create function to display time of the day -->
    </div>
  </div>
</template>
<script>
export default {
  components: {
  },

  data() {
    return {
      smartFM: false,
      user: {}
    };
  },
  computed: {
  },
  mounted() {
    this.checkUserDetails();
  },
  methods: {
    checkUserDetails() {
      this.user = JSON.parse(localStorage.getItem("shelterUser")).user;
      if (this.user.smartfm == 1) {
        return this.smartFM = true;
      }
    },
  },
  created() {
    this.$root.$refs.userx = this;
  }
  
};
</script>

<style scoped lang="scss">
.welcome-banner {
  background: linear-gradient(90deg, #0033EA 0%, #000 100%), #0033EA;
  box-shadow: 5px 0px 4px 0px rgba(0, 0, 0, 0.05);
  /* background: linear-gradient(to bottom right, #0033ea 41.75%, #000000 119.38%),
    #0033ea; */
  color: #fff;
  padding: 2rem 1rem 2rem;
  font-family: "Lato", sans-serif;
  border-radius: 10px;
  margin-top: 2rem;
  // margin-right: 20px;
}

.welcome-holder {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.fmio-text {
  font-size: 20px;
}

.welcome-banner h1 {
  font-weight: 300;
}
.welcome-banner span {
  font-weight: 700;
}

@media (max-width: 575.98px) {
  .welcome-banner h1 {
    font-size: 20px;
  }
  .fmio-text {
    font-size: 14px;
  }
}


</style>
