import client from "./requestclient";
const fm = {
    applyForSheltaFmIo: async () => {
        return await client({URL_PATH: "applyforsheltafm", method: "POST"});
    },
}

export default fm;


