<template>
    <div>
      <div class="row-bottom" v-if="subscription != 'YES'">
        <div class="bottom-row">
          <p>
            Please note, old and new Shelta users are eligible for the rewards.
          </p>
          <button
            class="btn"
            @click.prevent="
              membership();
            "
          >
            SUBSCRIBE
          </button>
        </div>
    </div>
    <SubscriptionModal />
    </div>
</template>

<script>
import SubscriptionModal from "../SubscriptionModal";
// import userCredentials from "../../services/userCredentials";
// import { Service } from "../store/service";
// const Api = new Service();
export default {
  name: "SubscriptionBtn",
  // props: {
  //   properties
  // },

  components: {
    SubscriptionModal
  },
  data() {
    return {
        user: "",
        subscription: ""
    };
  },
  //   created() {
  //     this.$root.$refs.PropertyManagementAgreement = this === "Property Management Agreement";
  //   },
  created() {
    this.subscription = this.$store.getters.getSubscription;
  },
  mounted() {
    if (localStorage.getItem("shelterUser")) {
      this.subscription;
      this.user = JSON.parse(localStorage.getItem("shelterUser")).user;
    }
  },
  methods: {
    membership() {
      return this.$root.$refs.D.openSubModal();
      // if (localStorage.getItem("shelterUser")) {
      //   return this.$root.$refs.D.openSubModal();
      // } else {
      //   this.$router
      //     .push({
      //       path: `/signup`
      //     })
      //     .catch(() => {});
      // }
    },
    // async getSubscription() {
    //   try {
    //     await userCredentials.userSubscribed(this.user.id)
    //       .then((res) => {
    //         // console.log("Subscribed check", res);
    //         if (res.success === "success") {
    //           this.subscribed = res.paid;
    //         }
    //         // return this.$store.commit("setApiSuccess", "Sub success");
    //       })
    //       .catch((err) => {
    //         console.log("err", err);
    //       });
    //   } finally {
    //     // console.log("closed");
    //   }
    // },
    // sure2023() {
    //   this.$gtag.event("become-a-user", {
    //     event_category: "become a user click",
    //     event_label: "Become a user button clicked",
    //     value: 1
    //   });
    // }
  }
};
</script>


<style lang="scss" scoped>
 $primary: #0033ea;
 $white: #ffffff;
 $shelta-gray: #555c74;

 .row-bottom {
  position: fixed;
  // width: 100%;
  width: calc(100% - 215px);;
  bottom: 0;
  z-index: 10;
}
.bottom-row {
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  background: $white;
  box-shadow: 0px 15px 40px 0px rgba(0, 0, 0, 0.4),
    0px 5px 10px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 1px rgba(0, 0, 0, 0.1);
  p {
    color: $shelta-gray;
    font-family: "Gotham-Book";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%;
    margin-bottom: 0;
  }
  button {
    background: $primary;
    color: $white;
    border-radius: 4px;
    text-align: center;
    font-family: "Gotham-Book";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 125%;
    height: auto;
    min-height: 48px;
    box-shadow: none;
    outline: 0;
  }
}
@media screen and (max-width: 599px) {
  .row-bottom {
    width: 100%;
    text-align: center;
  }
  .bottom-row {
    padding-top: 10px;
    padding-bottom: 10px;
    // height: 501px;
    height: auto;
    padding-left: 15px;
    padding-right: 15px;
    display: block;
    p {
      display: none;
    }
  }
}
</style>